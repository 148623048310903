export default {
  computed: {
    fileBtnOptions(){
      let result = [];
      if (['add', 'edit'].includes(this.formType)) {
        result.push({
          label: "新增",
          emit: "headImport",
          type: "button",
          btnOptType: "import",
        });
      }
      return result
    },
    userBtnOptions(){
      let result = [];
      if (['add', 'edit'].includes(this.formType)) {
        result.push({
          label: "新增",
          emit: "head-add",
          type: "button",
          btnOptType: "add",
        });
        result.push({
          label: "移除",
          emit: "head-remove",
          type: "button",
          btnOptType: "remove",
        });
      }
      return result
    },
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.formType)) {
        result.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        });
        result.push({
          label: "保存并返回",
          emit: "head-saveBack",
          type: "button",
          btnOptType: "saveBack",
        });
      }
      result.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        btnOptType: "cancel",
      });
      return result;
    },
  },
  data(){
    return {
      corePersonnelHeadBtnOptions: [
        {label: "新增", emit: "head-add", type: "button", icon: ""},
        {label: "导入", emit: "handleImport", type: "button", icon: ""},
        {label: "导出", emit: "head-export", type: "button", icon: ""},
        {label: "风险库", emit: "head-add", type: "button", icon: ""},
        {label: "移除", emit: "head-add", type: "button", icon: ""},
      ],
      formType: '',
      headTitle: '',
      mapBox: false,
      mapdata: '',
      dataForm: {
        id: '',
        areName: '',
        contractName: '',
        isDomestic: '1',
        latitude: '',
        longitude: '',
        prjAs: '',
        prjDate: '',
        prjInfoState: '',
        prjJoinDate: '',
        prjName: '',
        prjCode: '',
        prjState: '0',
      },
      corePersonnelTableOption: {
        index: true,
        indexLabel: "序号",
        menuWidth: 100,
        column: [{
          label: "检查内容",
          prop: "userName",
          align: "left",
          overHidden: true
        },
          {
            label: "负责人",
            prop: "birthday",
            width: 200,
            align: "center",
            overHidden: true,
            slot: true,
          },

        ],
      },
      tableData: [],
      corePersonnelTableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      defaultProps: {
        children: "children",
        label: "roleName",
      },
      organizationPersonnelTreeData: [],
      searchOrTitle:"structureName",
      activeName: 'corePersonnel',
      searchTitle:'roleName',
      corePersonnelTreeData: [],
      thirdPartyOrganizationTableOption: {
        index: true,
        indexLabel: "序号",
        menuWidth: 100,
        linklabel: "title",
        column: [{
          label: "人员名称",
          prop: "companyCode",
          align: "left",
          overHidden: true
        },
          {
            label: "所在组织部门",
            prop: "companyName",
            width: 200,
            align: "center",
            overHidden: true
          },
          {
            label: "角色",
            prop: "legalRep",
            width: 200,
            align: "center",
            overHidden: true
          },
          {
            label: "岗位",
            prop: "regLocation",
            width: 150,
            align: "center",
            overHidden: true
          },

        ],
      },
      thirdPartyOrganizationTableData: [],
      thirdPartyPersonnelTableData: [],
      rules: {
        prjAs: [{
          required: true,
          message: '请输入项目简称',
          trigger: 'blur'
        }],
        longitude: [{
          required: true,
          message: '请选择项目坐标',
          trigger: 'change'
        }],
        latitude: [{
          required: true,
          message: '请选择项目坐标',
          trigger: 'change'
        }],
        prjJoinDate: [{
          required: true,
          message: '请选择项入网日期',
          trigger: 'change'
        }],

      },
      prjDomesticList: [],
      selectionList: [],
      excelOption: {
        submitBtn: false,
        emptyBtn: false,
        excelForm: {},
        column: [{
          label: '数据导入',
          prop: 'excelFile',
          type: 'upload',
          drag: true,
          loadText: this.$t("cip.plat.sys.user.field.waiting"),
          span: 24,
          propsHttp: {
            res: 'data'
          },
          tip: this.$t("cip.plat.sys.user.field.format"),
          action: "/api/sinoma-hse-prj/thirdcompany/import"
        },
          {
            label: this.$t("cip.plat.sys.user.field.excelTemplate"),
            prop: 'excelTemplate',
            formslot: true,
            span: 24,
          }
        ]
      }
    }
  },
}
